import React from 'react'

const Footer = () => {
  return (
    <>
      <p style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#182838',color:'white',border:'1px solid #70707',padding:'10px'}}>2021 &copy; Laundry</p>
    </>
  )
}

export default Footer