import trousers from '../assets/trousers.jpg'
import boxers from '../assets/boxers.jpg'
import jeans from '../assets/jeans.jpg'
import others from '../assets/others.jpg'
import shirts from '../assets/shirt.jpg'
import tshirts from '../assets/tshirt.jpg'
import joggers from '../assets/joggers.jpg'
import bleach from '../assets/bleach.svg'
import fold from '../assets/towel.svg'
import iron from '../assets/ironing.svg'
import wash from '../assets/washing-machine.svg'
import bleachBlue from '../assets/bleachBlue.svg'
import foldBlue from '../assets/foldBlue.svg'
import ironBlue from '../assets/ironBlue.svg'
import washBlue from '../assets/washBlue.svg'



import facebook from '../assets/facebook.svg'
import instagram from '../assets/instagram.svg'
import linkedin from '../assets/linkedin.svg'
import list from '../assets/list.svg'
import home from '../assets/home.svg'
import add from '../assets/add.svg'
import listBlue from '../assets/listBlue.svg'
import homeBlue from '../assets/homeBlue.svg'
import addBlue from '../assets/addBlue.svg'

import padlock from '../assets/padlock.svg'
import search from '../assets/search.svg'
import Footer from '../assets/Footer.svg'
import eye from "../assets/eye.svg"
import avatar from "../assets/avatar.png"
import cancel from "../assets/cancel.png"

const obj={
trousers,boxers,jeans,others,shirts,tshirts,joggers,bleach,fold,iron,wash,bleachBlue,foldBlue,ironBlue,washBlue,facebook,instagram,linkedin,list,add,home,listBlue,addBlue,homeBlue,padlock,search,Footer,eye,avatar,cancel,
}


export default obj;


